import React from "react";
import App from "../components/App";
import OfferResult from "../components/OfferResult";
import OfferValuesSection from "../components/OfferResult/OfferValuesSection";

const Results = () => {
  return (
    <App headerChildren={<OfferValuesSection/>}>
      <OfferResult />
    </App>
  );
};

export default Results;
