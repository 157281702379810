import React, { useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { FaPhone } from "react-icons/all";

export const BottomButton = ({ text, textAfterClick, onClick, disabled }) => {
  const [isLoading, setISLoading] = useState(false);
  const [textToShow, setTextToShow] = useState("-");
  const [buttonUsed, setButtonUsed] = useState(false);

  useEffect(() => {
    setTextToShow(text);
  }, [text]);

  const handleOnClick = async () => {
    if (!onClick) {
      return;
    }
    setButtonUsed(true);
    setISLoading(true);
    await onClick();
    if (textAfterClick) {
      setTextToShow(textAfterClick);
    }
    setISLoading(false);
  };

  return (
    <div className={`control column  has-text-centered`}>
      <button
        className={`has-text-black is-size-4 is-size-4-desktop is-size-5-tablet leasingpoint-button is-primary ${
          isLoading ? "is-loading" : null
        }`}
        onClick={handleOnClick}
        disabled={disabled || buttonUsed}
      >
        {textToShow}
      </button>
    </div>
  );
};

export const MobilePhoneButton = () => (
  <StaticQuery
    query={graphql`
      query PhoneQuery {
        site {
          siteMetadata {
            phone
          }
        }
      }
    `}
    render={(data) => (
      <div className={`control column  has-text-centered`}>
        <a
          className="has-text-black is-size-4 is-size-4-desktop is-size-5-tablet leasingpoint-button is-primary"
          href={`tel:+48${data.site.siteMetadata.phone}`}
        >
          <FaPhone />
          &nbsp;Zadzwoń do nas
        </a>
      </div>
    )}
  />
);
