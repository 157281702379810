import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby";

const PkoImage = () => {
  const data = useStaticQuery(
    graphql`
      query PkoQuery {
        file(relativePath: { eq: "pko.png" }) {
          childImageSharp {
            fluid {
              aspectRatio
              sizes
              src
              srcSet
            }
          }
        }
      }
    `
  );
  return <Img fluid={data.file.childImageSharp.fluid} alt="pko" />;
};

export default PkoImage;
