import React, { useEffect, useState } from "react";
import { toLeasingPointFormatCurrency } from "../../../utils.js";
import { useQueryParam } from "gatsby-query-params";
import { fetchOfferRequest, timeoutOnFetch } from "../../../api/api";
import { NoRequestIdError, ServerError } from "../../Errors";

const getValueToLocaleString = (value) => {
  let localString;
  !value
    ? (localString = "-")
    : (localString = toLeasingPointFormatCurrency(value));
  return localString;
};

const ReqItem = ({ title, value }) => (
  <div className="column is-one-third">
    <div className="is-uppercase has-text-white has-text-weight-bold has-text-centered-mobile is-size-7-tablet is-size-6-desktop">
      {title}
    </div>
    <div className="is-uppercase has-text-black has-text-centered-mobile is-size-4 has-text-weight-bold">
      {value}
    </div>
  </div>
);

const OfferValuesSection = () => {
  const [requestData, setRequestData] = useState({});
  const requestId = useQueryParam("id");
  const [serverError, setServerError] = useState(false);
  const [requestIdIsExist, setRequestIdIsExist] = useState(true);

  useEffect(() => {
    if (!requestId) {
      return;
    }
    timeoutOnFetch(fetchOfferRequest({ requestId }))
      .then(async (response) => {
        let data = await response.json();
        if (!response.ok) {
          if (response.status === 404) {
            setRequestIdIsExist(false);
            sessionStorage.setItem("requestIdIsExist", JSON.stringify(false));
            return;
          }
          setServerError(true);
          return;
        }
        setRequestData(data);
        sessionStorage.setItem("requestData", JSON.stringify(data));
        sessionStorage.setItem("requestIdIsExist", JSON.stringify(true));
      })
      .catch(() => {
        setServerError(true);
      });
  }, [setRequestData, requestId]);

  let carType = requestData.carType === "SUV" ? "osobowe" : requestData.carType;
  let calculationNumberText = !requestData.id ? "-" : requestData.id;
  let leasingTimeText = !requestData.leasingTime
    ? "-"
    : `${requestData.leasingTime} miesięcy`;
  let carYearText = !requestData.carYear ? "-" : requestData.carYear;
  let carTypeText = !carType ? "-" : carType;
  let purchaseText = getValueToLocaleString(
    (requestData.purchasePercent * requestData.priceBrutto) / 100
  );
  let priceBruttoText = getValueToLocaleString(requestData.priceBrutto);
  let entryFeeText = getValueToLocaleString(
    (requestData.entryFeePercent * requestData.priceBrutto) / 100
  );

  return (
    <section className="section">
      {serverError ? <ServerError /> : null}
      {!requestIdIsExist ? <NoRequestIdError /> : null}
      <div className="container">
        <div className="is-fullwidth ">
          <h3 className="is-size-2 is-uppercase has-text-centered has-text-black">
            <span className="has-text-weight-light">Nr kalkulacji:</span>
            <br />
            <span className="has-text-weight-bold">
              {" "}
              {calculationNumberText}
            </span>
          </h3>
        </div>
        <div className="columns is-multiline">
          <ReqItem title="Rodzaj pojazdu" value={carTypeText} />
          <ReqItem title="Rok produkcji" value={carYearText} />
          <ReqItem title="Okres leasingu" value={leasingTimeText} />
          <ReqItem title="Cena pojazdu brutto" value={priceBruttoText} />
          <ReqItem title="Opłata wstępna brutto" value={entryFeeText} />
          <ReqItem title="Wykup brutto" value={purchaseText} />
        </div>
      </div>
    </section>
  );
};

export default OfferValuesSection;
