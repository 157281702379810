import React, { useState, useEffect, useCallback } from "react";
import * as R from "ramda";
import {
  fetchOffer,
  requestMailComparison,
  requestPhoneContact,
  timeoutOnFetch,
} from "../../api/api";
import { useInterval, usePathToNavigateBack } from "../../utils.js";
import { useQueryParam } from "gatsby-query-params";
import Provider from "./Provider";
import { BottomButton, MobilePhoneButton } from "./BottomButton";
import { FaPhone } from "react-icons/all";
import { ServerError, NoRequestIdError } from "../Errors";
import { isMobileOnly } from "react-device-detect";

const providers = [
  { id: 1, name: "alior", text: "Alior Leasing" },
  { id: 2, name: "noble", text: "Noble Finance" },
  { id: 3, name: "vehis", text: "Vehis" },
  { id: 4, name: "getin", text: "Idea Getin Leasing" },
  { id: 5, name: "pko", text: "PKO Leasing" },
  { id: 6, name: "cofidis", text: "Cofidis" },
  {
    id: 7,
    name: "santander",
    text: "Santander Consumer Multirent",
  },
];
const intervalTime = 5;

const buildData = (providers, results) => {
  let installmentsArray = results
    .filter((provider) => provider.leasingInstallment > 0)
    .map((item) => item.leasingInstallment);
  const highestInstallment = Math.max(...installmentsArray);
  return R.reduce(
    (data, provider) => {
      const result = results.find(
        (result) => result.provider === provider.name
      );
      const dataProvider = result
        ? {
            installment: result.leasingInstallment,
            errorCode: result.errorCode,
            resultId: result.id,
            isLoading: false,
            save: highestInstallment - result.leasingInstallment,
            ...provider,
          }
        : { ...provider, isLoading: true };
      return {
        ...data,
        [provider.name]: dataProvider,
      };
    },
    {},
    providers
  );
};

const CalculationResult = () => {
  const [results, setResults] = useState([]);
  const [requestData, setRequestData] = useState({});
  const [serverError, setServerError] = useState(false);
  const [requestIdIsExist, setRequestIdIsExist] = useState(true);
  const [offerFetchCounter, setOfferFetchCounter] = useState(0);
  const requestId = useQueryParam("id");
  const hasAll = results.length >= providers.length;
  const backToHome = usePathToNavigateBack();

  useInterval(
    () => {
      //TODO: Error?
      fetchOffer({ requestId }).then(async (res) => {
        if (!res.ok) {
          setServerError(true);
          return;
        }
        let data = await res.json();
        setResults(data.results);
        setOfferFetchCounter(offerFetchCounter + 1);
      });
    },
    hasAll || !requestIdIsExist ? null : intervalTime * 1000
  );

  useEffect(() => {
      setRequestIdIsExist(true);
  }, [])

  useEffect(() => {
      let sessionRequestData = JSON.parse(sessionStorage.getItem("requestData"));
      setRequestData(sessionRequestData);
      let sessionRequestIdIsExist = JSON.parse(sessionStorage.getItem("requestIdIsExist"));
      if (typeof sessionRequestIdIsExist === "boolean") {
         setRequestIdIsExist(sessionRequestIdIsExist)
      }
      sessionStorage.removeItem("requestIdIsExist");
  }, [setRequestData, setRequestIdIsExist, offerFetchCounter === 1, requestId])


  const data = buildData(providers, results);

  const sendMail = useCallback(async () => {
    await timeoutOnFetch(requestMailComparison({ requestId }))
      .then((response) => {
        if (!response.ok) {
          setServerError(true);
          return false;
        }
      })
      .catch(() => {
        setServerError(true);
      });
  }, [requestId]);

  const callToClient = useCallback(async () => {
    await timeoutOnFetch(requestPhoneContact({ requestId }))
      .then((response) => {
        if (!response.ok) {
          setServerError(true);
          return false;
        }
      })
      .catch(() => {
        setServerError(true);
      });
  }, [requestId]);

  return (
    <div className="has-background-info">
      {serverError ? <ServerError /> : null}
      {!requestIdIsExist ? <NoRequestIdError /> : null}
      <section className="section">
        <div className="container ">
          {providers.map((provider) => (
            <Provider
              key={provider.id}
              {...data[provider.name]}
              requestId={requestId}
              requestIdIsExist={requestIdIsExist}
              setServerError={setServerError}
              leasingTime={requestData ? requestData.leasingTime : 0}
              offerFetchCounter={offerFetchCounter}
              intervalTime={intervalTime}
            />
          ))}
        </div>
      </section>
      <section className="section has-background-white">
        <div className="container">
          <div className="columns">
            <BottomButton onClick={backToHome} text="Powrót" />
            {isMobileOnly ? (
              <MobilePhoneButton />
            ) : (
              <BottomButton
                onClick={callToClient}
                disabled={!requestIdIsExist || !hasAll}
                text={
                  <>
                    <FaPhone size="1em" />
                    &nbsp;Zamów kontakt
                  </>
                }
                textAfterClick="Dziękujemy, zadzwonimy do Ciebie!"
              />
            )}
            <BottomButton
              onClick={sendMail}
              disabled={!requestIdIsExist || !hasAll}
              text="Wyślij porównanie na mail"
              textAfterClick="Mail wysłany. Dziękujemy"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CalculationResult;
