import React, { useCallback, useEffect, useState } from "react";
import LoadingGridBox from "./LoadingGridBox";
import { navigate } from "@reach/router";
import { applicationStarted } from "../../../api/api";
import AliorImage from "./ProvidersImages/AliorImage";
import CofidisImage from "./ProvidersImages/CofidisImage";
import GetinImage from "./ProvidersImages/GetinImage";
import PkoImage from "./ProvidersImages/PkoImage";
import NobleImage from "./ProvidersImages/NobleImage";
import SantanderImage from "./ProvidersImages/SantanderImage";
import VehisImage from "./ProvidersImages/VehisImage/Vehis";

export const GridBox = ({ children }) => (
  <div className="column center has-background-white offerresult-gridbox">
    {children}
  </div>
);

const Provider = ({
  name,
  requestId,
  isLoading,
  installment,
  resultId,
  text,
  save,
  requestIdIsExist,
  setServerError,
  leasingTime,
  errorCode,
  offerFetchCounter,
  intervalTime,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (installment) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [installment, setIsButtonDisabled]);

  const startApplication = useCallback(async () => {
    await applicationStarted({ requestId, resultId }).then((response) => {
      if (!response.ok) {
        setServerError(true);
        return;
      }
    });
    let linkToAppForm = `/app?id=${requestId}&resultid=${resultId}`;
    navigate(linkToAppForm);
  }, [requestId, resultId]);

  let image;
  switch (name) {
    case "alior":
      image = <AliorImage />;
      break;
    case "cofidis":
      image = <CofidisImage />;
      break;
    case "getin":
      image = <GetinImage />;
      break;
    case "noble":
      image = <NobleImage />;
      break;
    case "pko":
      image = <PkoImage />;
      break;
    case "santander":
      image = <SantanderImage />;
      break;
    case "vehis":
      image = <VehisImage />;
      break;
    default:
      null;
  }

  return (
    <div className="columns is-variable is-fullwidth offerresult-row">
      <GridBox>
        <div className="image-div">{image}</div>
      </GridBox>
      <GridBox>
        <h1 className="title is-size-3 is-size-3-desktop is-size-5-tablet is-uppercase has-text-centered">
          {text.split(" ").map((word) => {
            return (
              <span key={word}>
                {word}
                <br />
              </span>
            );
          })}
        </h1>
      </GridBox>
      <LoadingGridBox
        installment={installment}
        isLoading={isLoading}
        errorCode={errorCode}
        requestIdIsExist={requestIdIsExist}
        save={save}
        leasingTime={leasingTime}
        offerFetchCounter={offerFetchCounter}
        intervalTime={intervalTime}
      />
      <GridBox>
        <button
          className="leasingpoint-button is-large has-text-white is-primary is-size-4 is-size-5-desktop is-size-5-tablet"
          onClick={startApplication}
          disabled={isButtonDisabled}
        >
          Wypełnij wniosek
        </button>
      </GridBox>
    </div>
  );
};

export default Provider;
