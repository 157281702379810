import React, { useEffect, useState } from "react";
import { Text } from "../../../ApplicationSent";
import { GridBox } from "../index";
import {
  useInterval,
  toLeasingPointFormatCurrency,
} from "../../../../utils.js";

const timeoutSeconds = 30;
const firstTimeMessage = "Liczymy dla Ciebie";
const secondTimeMessage =
  "Prosimy o jeszcze trochę cierpliwości, to może potrwać do kilku minut";
const noOfferMessage =
  "Przykro nam ale na ten moment nie mamy oferty dla Ciebie, spróbuj ponownie za jakiś czas.";
const preconditionFailedMessage =
  "Wybrane przez Ciebie parametry wykraczają poza zasięg oferty.";

const Loader = () => <div className="loader"> </div>;

const LoadingGridBox = ({
  isLoading,
  requestIdIsExist,
  installment,
  save,
  leasingTime,
  errorCode,
  offerFetchCounter,
  intervalTime,
}) => {
  const [timeMessage, setTimeMessage] = useState(firstTimeMessage);
  const [counter, setCounter] = useState(30);
  const [isTimeoutEnd, setIsTimeoutEnd] = useState(false);

  useInterval(
    () => {
      if (counter === 0) {
        return;
      }
      setCounter(counter - 1);
    },
    isTimeoutEnd ? null : 1000
  );

  useEffect(() => {
    if (offerFetchCounter === Math.ceil(timeoutSeconds / intervalTime)) {
      setTimeMessage(secondTimeMessage);
    }
    const changeTimeMessage = setTimeout(() => {
      setIsTimeoutEnd(true);
    }, timeoutSeconds * 1000);
    if (!isLoading) {
      setIsTimeoutEnd(true);
      clearTimeout(changeTimeMessage);
    }
  }, [isLoading, offerFetchCounter, intervalTime, timeoutSeconds]);

  let installmentToShow;
  let saveToShow;
  if (installment) {
    installmentToShow = (
      <h3 className="has-text-primary is-size-3 is-size-4-touch has-text-weight-bold has-text-centered">
        {toLeasingPointFormatCurrency(installment)}
      </h3>
    );
    if (save > 0) {
      saveToShow = (
        <h2 className="is-size-5 has-text-centered">
          oszczędzasz: &nbsp;
          <br />
          <span className="has-text-weight-bold">
            {toLeasingPointFormatCurrency(save * leasingTime)}
          </span>
        </h2>
      );
    }
  } else if (errorCode) {
    installmentToShow = (
      <h2 className="has-text-centered is-size-5">
        {errorCode === "precondition_failed"
          ? preconditionFailedMessage
          : noOfferMessage}
      </h2>
    );
  }

  return (
    <GridBox>
      {isLoading && requestIdIsExist ? (
        <div className="container">
          <div className="center">
            {isTimeoutEnd ? <Loader /> : <h2>{counter}</h2>}
          </div>
          <Text>{timeMessage}</Text>
        </div>
      ) : (
        <div>
          {installmentToShow}
          {saveToShow}
        </div>
      )}
    </GridBox>
  );
};

export default LoadingGridBox;
